//Backend URL:

// export const BASE_URL = "http://localhost:3200";
// export const BASE_URL = "https://dev-api.skortorent.com";
export const BASE_URL = "https://api.skortorent.com";

//Stagging
//export const BASE_URL = "https://uno.dev.seraphic.io/api";
// export const BASE_URL = "https://api.unorenting.com"

//Live
// export const BASE_URL = "http://54.164.159.66:4000";
// export const BASE_URL = "https://api.dygres.com";
